<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">
          Consequências
        </div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Consequências de não Consentimento"
          message="Preciso de ajuda para cadastrar as consequências de não consentimento."
        >
          <p>
            De acordo com LGPD, o Titular se reserva no direito de recusar consentimentos para tratamento de dados, sujeito às consequências listadas pelo controlador.
          </p>
          <p>
            Para proteção judicial da empresa, é importante que o titular esteja ciente das consequências <b>caso recuse</b> o consentimento solicitado.
            No <b>aplicativo</b>, as consequências serão bastante destacadas, de maneira que o titular visualize-as claramente antes de recusar um consentimento.
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Trechos da LGPD relativos às Consequências de não Consentimento
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  <b>Art. 18 </b>: O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:
                </p>
                <ul>
                  <li>...</li>
                  <li>
                    <b>VIII</b> informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
                  </li>
                  <li>...</li>
                  <li>
                    <b>§ 2º</b> O titular pode opor-se a tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento, em caso de descumprimento ao disposto nesta Lei.
                  </li>
                </ul>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openConsequenceDialog()">
          Nova Consequência
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="consequences"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, aguarde"
        hide-default-footer
        :items-length="1"
        no-data-text="Nenhuma Consequência Informada"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openConsequenceDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon> 
          </v-btn>
          <v-btn icon @click="deleteDialog = true; consequence_id = item.id">
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog v-model="consequenceDialog" max-width="800" :persistent="loadingSave">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="consequenceForm.id">
            Edição de Consequência
          </div>
          <div v-else>
            Nova Consequência
          </div>
          <div>
            <v-btn icon @click="consequenceDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
        <v-form ref="form" @submit.prevent="saveConsequence()">
          <v-text-field
            v-model="consequenceForm.title"
            label="Título *"
            outlined
            hide-details="auto"
            color="black"
            background-color="white"
            :disabled="loadingSave"
            :rules="[$rules.required]"
          />
          <v-row no-gutters>
            <v-textarea
              v-model="consequenceForm.description"
              label="Descrição *"
              outlined
              hide-details="auto"
              color="black"
              class="my-3 mr-1"
              background-color="white"
              :disabled="loadingSave"
              :rules="[$rules.required]"
            />
          </v-row>
          <v-card-actions class="pt-3 pb-0 pr-0">
            <v-spacer/>
            <v-btn color="secondary" class="mr-1" text @click="consequenceDialog = false" :disabled="loadingSave">
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="loadingSave">
              <template v-if="consequenceForm.id">
                Salvar
              </template>
              <template v-else>
                Criar
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500" :persistent="loadingDelete">
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Controlador?  
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
            <v-btn color="secondary" class="mr-1" text :disabled="loadingDelete" @click="deleteDialog = false">Cancelar</v-btn>
            <v-btn class="ml-1"  color="error" text :loading="loadingDelete" @click="deleteConsequence()">
              Excluir
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Consequences",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingDelete: false,
    loadingSave: false,
    consequenceDialog: false,
    deleteDialog: false,
    consequence_id: -1,
    consequenceForm: {
      title: "",
      description: "",
    },
    headers: [
      {
        text: "Título",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "title",
      },
      { text: "Descrição", value: "description", align: "start" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    consequences: [],
  }),
  /* mounted() {
    this.getConsequences();
  }, */
  mounted() {
    this.getConsequences();
  },
  methods: {
    saveConsequence(){
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if(this.consequenceForm.id){
          request = this.$axios.put(`/consequence/${this.consequenceForm.id}`, this.consequenceForm)
        } else {
          request = this.$axios.post("/consequence", this.consequenceForm)
        }
        request
        .then((response) => {
          this.$showMessage("success", "Nova Consequência Salva");
          this.getConsequences();
          this.consequenceDialog = false;
        })
        .catch((error) => {
          if(error.response){
            if(error.response.status == 404){
              this.$showMessage("error", "Consequência não encontrada")
              return
            }
            this.$showMessage("error", "Ocorreu um erro Inesperado")
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
      }
    },
    deleteConsequence(){
      this.loadingDelete = true;
      this.$axios.delete(`/consequence/${this.consequence_id}`)
      .then((response) => {
        this.$showMessage("success", "Consequência Excluida");
        this.getConsequences();
        this.deleteDialog = false
      })
      .catch((error) => {
        if (error.response) {
          if(error.response.status == 404) {
            this.$showMessage("error", "Consequência não encontrada")
            return
          }
          this.$showMessage("error", "Ocorreu um erro Inesperado")
        }
      })
      .finally(() =>  {
        this.loadingDelete = false;
      })
    },
    getConsequences(){
      this.loading = true;
      this.$axios.get("/consequences")
      .then((response) => {
        this.consequences = response.data
      })
      .catch((error) => {
        this.$showMessage("error", "Não foi possivel carregar os dados")
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openConsequenceDialog(consequence) {
      if(consequence){
        this.consequenceForm = Object.assign({}, consequence)
      } else {
        this.consequenceForm = {}
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      }
      this.consequenceDialog = true; 
    }
  }
};
</script>